import { useState } from "react";
import { Group, Code } from "@mantine/core";
import {
  IconHighlight,
  IconDeviceComputerCamera,
  IconTextCaption,
  IconWand,
  IconSettings,
} from "@tabler/icons-react";
import "./style.css";

import styles from "./style.module.css";
import { NavLink } from "react-router-dom";
import { navbarLinks } from "../routes";

export function NavbarSimple({ toggle }) {
  const links = navbarLinks.map((item) => (
    <NavLink
      onClick={toggle}
      className={styles.link}
      to={item.link}
      key={item.label}
    >
      <item.icon className={styles.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </NavLink>
  ));

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarMain}>
        <Group className={styles.header} justify="space-between"></Group>
        {links}
      </div>
    </nav>
  );
}

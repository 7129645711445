import { useAuth, useUser } from "@clerk/clerk-react";
import * as React from "react";
import { Button, Divider, Loader, Stack, Text, Title } from "@mantine/core";
import useRailsToast from "@/components/utils/use-rails-toast";
import NotificationPreferences from "./notification-preferences";

const Settings = () => {
  const { user } = useUser();
  const railsToast = useRailsToast();
  const { signOut } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);

  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const deleteAccount = async () => {
    if (!confirmation) {
      setConfirmation(true);
    } else {
      setLoading(true);
      const response = await fetch(`/api/v1/users/${user.id}`, {
        method: "DELETE",
      });
      const status = response.status;
      if ([204].includes(status)) {
        railsToast({ info: "Your account has been succcessfully deleted." });
        setTimeout(() => {
          signOut();
          window.location.href = "https://offtake.co";
        }, 2000);
      } else {
        setLoading(false);
      }
    }
  };

  if (loading && !confirmation) {
    return <Loader />;
  }

  return (
    <Stack align="flex-start" spacing="6" pt="12">
      <Title size="h1">Settings</Title>
      <Title size="h2">Delete Account</Title>

      <Button
        color={confirmation && "red"}
        loading={loading}
        onClick={deleteAccount}
      >
        {confirmation ? "Confirm Delete Account?" : "Delete Account"}
      </Button>
      <Divider />
      <Title size="h2 ">Notification Preferences</Title>
      <NotificationPreferences />
    </Stack>
  );
};

export default Settings;

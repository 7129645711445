import UsersCurrentShow from "@/components/users/current-show";
import {
  Button,
  Divider,
  Group,
  Table,
  Title,
  Text,
  Box,
  TextInput,
} from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import { Subtitle } from "../types";
import { useDebouncedValue } from "@mantine/hooks";
import SearchBody from "./search-body";

const SubtitleSearch = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState("");
  const [debouncedSearch] = useDebouncedValue(search, 500);
  const [subtitles, setSubtitles] = React.useState<Subtitle>([]);

  const fetchSubtitles = async () => {
    const response = await fetch(`/api/v1/subtitles?term=${search}`);
    const json = await response.json();
    const status = response.status;
    setLoading(false);
    if ([200, 304].includes(status)) {
      setSubtitles(json);
    }
  };

  React.useEffect(() => {
    search && fetchSubtitles();
  }, [debouncedSearch]);

  return (
    <>
      <TextInput
        size="xl"
        placeholder="Exciting Basketball Clips"
        value={search}
        onChange={(event) => {
          !loading && setLoading(true);
          setSearch(event.currentTarget.value);
        }}
      />

      <SearchBody subtitles={subtitles} loading={loading} search={search} />
    </>
  );
};

export default SubtitleSearch;

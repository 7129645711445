//@ts-ignore
import { DirectUploadProvider } from "./active-storage-provider";
import { useState } from "react";
import * as React from "react";
import useRailsToast from "../use-rails-toast";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { Group, rem, Text } from "@mantine/core";
import { IconUpload, IconX, IconPhoto } from "@tabler/icons-react";

// TODO(adenta) HERE BE DRAGONS. The underlying react-activestorage-provider library
// is no longer being maintained. the onError callback does not work at all.
// To get this to work with react 17 at all we had to upgrade the deps manually
// We need to rebuild this, maybe in it's own library?
const ActiveStorageUploader = ({
  onSuccess,
  onError,
  multiple = false,
}: {
  onSuccess: Function;
  onError: Function;
  multiple?: boolean;
}) => {
  const railsToast = useRailsToast();
  const onSuccessAndToast = (props) => {
    railsToast(
      { info: "Files were uploaded to the server, but have yet to be saved." },
      200,
    );
    onSuccess(props);
  };
  const onErrorAndToast = (props?) => {
    railsToast({ info: "Files were not uploaded. Please try again." }, 500);
    onError(props);
  };
  const [uploadedFileCount, setUploadedFileCount] = useState<number>(0);
  const [someFilesDidntUpload, setSomeFilesDidntUpload] =
    useState<boolean>(false);
  React.useEffect(() => {
    if (someFilesDidntUpload) {
      setSomeFilesDidntUpload(false);
      onErrorAndToast();
    }
  }, [someFilesDidntUpload]);

  if (someFilesDidntUpload) {
    return <></>;
  }

  return (
    <DirectUploadProvider
      onError={onErrorAndToast}
      onSuccess={(signedIds: string[]) => {
        setUploadedFileCount(signedIds.length);
        onSuccessAndToast(signedIds);
      }}
      render={({
        handleUpload,
        uploads,
        ready,
      }: {
        handleUpload: Function;
        uploads: any;
        ready: boolean;
      }) => {
        const dropzoneText = () => {
          if (uploads.filter(({ error }) => !!error).length > 0) {
            setSomeFilesDidntUpload(true);
            return "There was an issue uploading these files, please try again.";
          } else if (uploads.length == 1 && uploads[0].progress) {
            return `Uploading, ${JSON.stringify(
              Math.round(uploads[0].progress, 2),
            )}% complete.`;
          } else if (uploads.length > 0) {
            return `Uploading...`;
          } else if (uploadedFileCount > 0) {
            return "Upload Successful";
          } else {
            return `Drop your file${multiple ? "s" : ""}...`;
          }
        };
        return (
          <div>
            <Dropzone
              onDrop={(files) => handleUpload(files)}
              message={dropzoneText()}
              multiple={multiple}
            >
              <Group
                justify="center"
                gap="xl"
                mih={220}
                style={{ pointerEvents: "none" }}
              >
                <Dropzone.Accept>
                  <IconUpload
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-blue-6)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-red-6)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconPhoto
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-dimmed)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Idle>

                <div>
                  <Text size="xl" inline>
                    Drag images here or click to select files
                  </Text>
                  <Text size="sm" c="dimmed" inline mt={7}>
                    {dropzoneText()}
                  </Text>
                </div>
              </Group>
            </Dropzone>
            {/* There is a better way to add previews here https://react-dropzone.js.org/#section-previews */}
            {/* {uploads.map((upload) => {
              switch (upload.state) {
                case "waiting":
                  return (
                    <p key={upload.id}>Waiting to upload {upload.file.name}</p>
                  );
                case "uploading":
                  return (
                    <p key={upload.id}>
                      Uploading {upload.file.name}: {upload.progress}%
                    </p>
                  );
                case "error":
                  return (
                    <p key={upload.id}>
                      Error uploading {upload.file.name}: {upload.error}
                    </p>
                  );
                case "finished":
                  return (
                    <p key={upload.id}>Finished uploading {upload.file.name}</p>
                  );
              }
            })} */}
          </div>
        );
      }}
    />
  );
};

export default ActiveStorageUploader;

// <ActiveStorageUploader
// onSuccess={(signedIds: string[]) => {
//   setEvent({
//     ...event,
//     video: signedIds[0],
//   });
// }}
// onError={() => {
//   setEvent({
//     ...event,
//     video: null,
//   });
// }}
// />

import { Subtitle } from "@/components/subtitles/types";
import {
  Stepper,
  Group,
  Button,
  Affix,
  Center,
  Text,
  Textarea,
  Table,
  Checkbox,
} from "@mantine/core";
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
const AIHighlights = () => {
  const [search, setSearch] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const [active, setActive] = React.useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const [subtitles, setSubtitles] = React.useState<Subtitle>([]);

  const fetchSubtitles = async () => {
    const response = await fetch(`/api/v1/subtitles?term=${search}`);
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setSubtitles(json);
      setLoading(false);
      nextStep();
    }
  };

  const saveAsHighlights = async () => {
    selectedRows.map(async (subtitleId) => {
      await fetch(`/api/v1/subtitles/${subtitleId}/save_as_highlight`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    });
    setLoading(false);
    nextStep();
  };

  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const rows = subtitles.map((subtitle, i) => (
    <Table.Tr
      key={subtitle.id}
      bg={
        selectedRows.includes(subtitle.id)
          ? "var(--mantine-color-blue-light)"
          : undefined
      }
    >
      <Table.Td>
        <Checkbox
          aria-label="Select row"
          checked={selectedRows.includes(subtitle.id)}
          onChange={(event) =>
            setSelectedRows(
              event.currentTarget.checked
                ? [...selectedRows, subtitle.id]
                : selectedRows.filter((id) => id !== subtitle.id),
            )
          }
        />
      </Table.Td>
      <Table.Td>{subtitle.body}</Table.Td>
      <Table.Td>
        <Link
          target="_blank"
          to={`/recorded_streams/${subtitle.recordedStreamId}?t=${
            subtitle.startTimestamp - 5
          }`}
        >
          Preview
        </Link>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="Search For Clips">
          <Center p="8">
            <Textarea
              autosize
              value={search}
              onChange={(e) => {
                setSearch(e.currentTarget.value);
              }}
              size="xl"
              label="Search"
              description={
                <Group py="8">
                  What kind of content are you looking for?{" "}
                  <Button
                    size="compact-xs"
                    onClick={() => {
                      const choices = [
                        "Exciting Made Baskets by Lebron James, LA Lakers, for 2, for 3, pulls up, steps back",
                        "Made Baskets by Cody Zeller, Charlotte Hornets, drain, swish, bang, knocks",
                        "Kevan Durant Golden State warriors huge monster big beautiful, high flying dunk, electrifying, sensational, poster, posts up in traffic",
                      ];
                      setSearch(
                        choices[Math.floor(Math.random() * choices.length)],
                      );
                    }}
                  >
                    Suprise Me
                  </Button>
                </Group>
              }
            />
          </Center>
          <Center p="8">
            <Button
              loading={loading}
              disabled={search == ""}
              onClick={() => {
                setLoading(true);
                fetchSubtitles();
              }}
            >
              Search
            </Button>
          </Center>
        </Stepper.Step>
        <Stepper.Step label="Preview Clips">
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th />
                <Table.Th>Highlight</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
          <Center p="8">
            <Button
              loading={loading}
              disabled={selectedRows.length == 0}
              onClick={() => {
                setLoading(true);
                saveAsHighlights();
              }}
            >
              Save as Highlights
            </Button>
          </Center>
        </Stepper.Step>
        <Stepper.Step label="Save Clips">
          Clips are now processing, and will be available in the mobile app
          shortly!
        </Stepper.Step>
        <Stepper.Completed>
          Completed, click back button to get to previous step
        </Stepper.Completed>
      </Stepper>
      <Affix position={{ bottom: 20, right: 20 }}>
        <Group justify="center" mt="xl">
          <Button
            disabled={active == 0 || active == 2}
            variant="default"
            onClick={prevStep}
          >
            <IconArrowLeft />
          </Button>
        </Group>
      </Affix>
    </>
  );
};

export default AIHighlights;
function useState<T>(arg0: undefined[]): [any, any] {
  throw new Error("Function not implemented.");
}

import { LIKE, Reaction } from "@/components/reactions/types";
import useRailsToast from "@/components/utils/use-rails-toast";
import { Checkbox } from "@mantine/core";
import { useState, useEffect } from "react";
const LikeButton = ({ highlightId }) => {
  const railsToast = useRailsToast();
  const [loading, setLoading] = useState(true);
  const [reactions, setReactions] = useState<Reaction>([]);
  const likeReaction = reactions.find(({ reactionType }) => {
    return reactionType == LIKE;
  });
  const fetchReactions = async () => {
    const response = await fetch(`/api/v1/highlights/${highlightId}/reactions`);
    const json = await response.json();
    const status = response.status;
    setLoading(false);
    if ([200, 304].includes(status)) {
      setReactions(json);
    }
  };

  const createLike = async () => {
    setLoading(true);
    const response = await fetch(`/api/v1/reactions`, {
      method: "POST",
      body: JSON.stringify({ highlightId, reactionType: LIKE }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([201].includes(status)) {
      fetchReactions();
    } else {
      setLoading(false);
      railsToast(json, status);
    }
  };

  const destroyLike = async () => {
    setLoading(true);
    const response = await fetch(`/api/v1/reactions/${likeReaction.id}`, {
      method: "DELETE",
    });
    const status = response.status;
    if ([204].includes(status)) {
      fetchReactions();
    } else {
      setLoading(false);
      railsToast(await response.json(), status);
    }
  };

  useEffect(() => {
    fetchReactions();
  }, []);

  return (
    <Checkbox
      styles={{ input: { cursor: "pointer" }, label: { cursor: "pointer" } }}
      disabled={loading}
      label="Like"
      checked={!!likeReaction}
      onChange={(event) => {
        if (!!likeReaction) {
          destroyLike();
        } else {
          createLike();
        }
      }}
    />
  );
};

export default LikeButton;

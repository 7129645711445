import { Button, Stack, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { SearchableMultiSelect } from "./searchable-multiselect";
import { NbaTeam } from "@/components/nba-teams/types";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
const NbaTeamPreferenceSearch = () => {
  const { userId } = useAuth();
  const railsToast = useRailsToast();

  const [value, setValue] = useState<NbaTeam[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const updateUserNbaTeamPreferences = async () => {
    setLoading(true);
    const updateUserResult = await fetch(`/api/v1/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: { nbaTeamPreferences: value.map((v) => v.id) },
      }),
    });

    setLoading(false);

    if (updateUserResult.status === 200) {
      railsToast(
        { info: "NBA Team Preferences were successfully updated!" },
        updateUserResult.status,
      );
    } else {
      Promise.reject(updateUserResult.statusText);
    }
  };

  return (
    <Stack>
      <Title>NBA Team Preferences</Title>
      {<SearchableMultiSelect value={value} setValue={setValue} />}
      <Button loading={loading} onClick={updateUserNbaTeamPreferences}>
        Save
      </Button>
    </Stack>
  );
};
export default NbaTeamPreferenceSearch;

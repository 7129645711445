import useRailsToast from "@/components/utils/use-rails-toast";
import { Button, Group, List, ListItem, Stack, Textarea } from "@mantine/core";
import { useEffect, useState } from "react";
const Comments = ({ highlightId }) => {
  const railsToast = useRailsToast();
  const [body, setBody] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState<Comment>([]);

  const fetchComments = async () => {
    const response = await fetch(`/api/v1/highlights/${highlightId}/comments`);
    const json = await response.json();
    const status = response.status;
    setLoading(false);
    if ([200, 304].includes(status)) {
      setComments(json);
    }
  };

  const createComment = async () => {
    setLoading(true);
    const response = await fetch(`/api/v1/comments`, {
      method: "POST",
      body: JSON.stringify({ highlightId, body: body }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const json = await response.json();
    setBody("");
    setLoading(false);

    if ([201].includes(status)) {
      fetchComments();
    } else {
      railsToast(json, status);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <Stack align="stretch">
      <List>
        {comments.map((comment, i) => {
          return <ListItem key={i}>{comment.body}</ListItem>;
        })}
      </List>
      <Group grow={0}>
        <Textarea
          value={body}
          onChange={(e) => {
            setBody(e.currentTarget.value);
          }}
          w="500px"
        />
        <Button disabled={loading || !body} onClick={createComment} w="1px">
          Submit
        </Button>
      </Group>
    </Stack>
  );
};

export default Comments;

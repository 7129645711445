import { Loader, Text } from "@mantine/core";
import { useState, useEffect } from "react";
import { CourierProvider } from "@trycourier/react-provider";
import { useAuth } from "@clerk/clerk-react";
import { PreferencesV4 } from "@trycourier/react-preferences";

const NotificationPreferences = () => {
  const { userId } = useAuth();
  const [courierInboxToken, setCourierInboxToken] = useState<string>("");

  const fetchCourierInboxToken = async () => {
    const response = await fetch(`/api/v1/users/courier_inbox_token`);
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setCourierInboxToken(json.token);
    }
  };

  useEffect(() => {
    fetchCourierInboxToken();
  }, []);

  if (!courierInboxToken) {
    return <Loader />;
  }

  return (
    <CourierProvider
      userId={userId}
      authentication={courierInboxToken}
      clientKey={import.meta.env.VITE_COURIER_CLIENT_KEY}
    >
      <PreferencesV4 />
    </CourierProvider>
  );
};

export default NotificationPreferences;

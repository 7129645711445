import React from "react";
import { createRoot } from "react-dom/client";
import Routes from "./layout/routes";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import "@mantine/notifications/styles.css";
import "@mantine/dropzone/styles.css";
import { Notifications } from "@mantine/notifications";

const App = () => {
  return (
    <React.StrictMode>
      <MantineProvider defaultColorScheme="dark">
        <Notifications position="top-right" />
        <Routes />
      </MantineProvider>
    </React.StrictMode>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  const root = createRoot(document.getElementById("root"));
  root.render(<App />);
});

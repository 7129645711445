import UsersCurrentShow from "@/components/users/current-show";
import {
  Button,
  Divider,
  Group,
  Table,
  Title,
  Text,
  Box,
  rem,
} from "@mantine/core";
import { IconShare } from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
const HighlightSearch = () => {
  const [highlights, setHighlights] = React.useState([]);

  const fetchHighlights = async () => {
    const response = await fetch(`/api/v1/highlights`);
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setHighlights(json);
    }
  };

  React.useEffect(() => {
    fetchHighlights();
  }, []);

  return (
    <>
      <UsersCurrentShow />
      <Divider />
      <Group justify="space-between">
        <Title p="sm" size="md">
          Highlights
        </Title>
        <Button component={Link} to="/highlights/new" size="compact-sm">
          +
        </Button>
      </Group>
      <Table
        data={{
          head: ["Created At", "URL", "Created By"],
          body: highlights.map(
            ({ createdAt, id, createdByUsername, videoUrl }) => {
              return [
                <Button
                  component={Link}
                  to={`/highlights/${id}`}
                  variant="subtle"
                  size="compact-sm"
                >
                  {new Date(createdAt).toLocaleDateString()}
                </Button>,
                <Text lineClamp={1}>{videoUrl}</Text>,

                createdByUsername,
              ];
            },
          ),
        }}
      />
    </>
  );
};

export default HighlightSearch;

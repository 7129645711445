import UsersCurrentShow from "@/components/users/current-show";
import {
  Button,
  Divider,
  Group,
  Table,
  Title,
  Text,
  Box,
  rem,
} from "@mantine/core";
import { IconShare } from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
const RecordedStreamSearch = () => {
  const [recordedStreams, setRecordedStreams] = React.useState([]);

  const fetchRecordedStreams = async () => {
    const response = await fetch(`/api/v1/recorded_streams`);
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setRecordedStreams(json);
    }
  };

  React.useEffect(() => {
    fetchRecordedStreams();
  }, []);

  return (
    <>
      <UsersCurrentShow />
      <Divider />
      <Group justify="space-between">
        <Title p="sm" size="md">
          Recorded Streams
        </Title>
        <Button component={Link} to="/recorded_streams/new" size="compact-sm">
          +
        </Button>
      </Group>
      <Table
        data={{
          head: ["Created At", "URL"],
          body: recordedStreams.map(({ createdAt, id, videoUrl }) => {
            return [
              <Button
                component={Link}
                to={`/recorded_streams/${id}`}
                variant="subtle"
                size="compact-sm"
              >
                {new Date(createdAt).toLocaleDateString()}
              </Button>,
              <Text lineClamp={1}>{videoUrl}</Text>,
            ];
          }),
        }}
      />
    </>
  );
};

export default RecordedStreamSearch;

import { SignedIn, SignedOut } from "@clerk/clerk-react";
import UsersCurrentShow from "../users/current-show";
import Splash from "./splash";
import HighlightSearch from "../highlights/search";

const Home = () => {
  return (
    <>
      <SignedIn>
        <HighlightSearch />
      </SignedIn>
      <SignedOut>
        <Splash />
      </SignedOut>
    </>
  );
};

export default Home;

import { Button, Stack, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { SearchableMultiSelect } from "./searchable-multiselect";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import { NbaAthlete } from "@/components/nba-athletes/types";
const NbaAthletePreferenceSearch = () => {
  const { userId } = useAuth();
  const railsToast = useRailsToast();

  const [value, setValue] = useState<NbaAthlete[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const updateUserNbaAthletePreferences = async () => {
    setLoading(true);
    const updateUserResult = await fetch(`/api/v1/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: { nbaAthletePreferences: value.map((v) => v.id) },
      }),
    });

    setLoading(false);

    if (updateUserResult.status === 200) {
      railsToast(
        { info: "NBA Athlete Preferences were successfully updated!" },
        updateUserResult.status,
      );
    } else {
      Promise.reject(updateUserResult.statusText);
    }
  };

  return (
    <Stack>
      <Title>NBA Athlete Preferences</Title>
      {<SearchableMultiSelect value={value} setValue={setValue} />}
      <Button loading={loading} onClick={updateUserNbaAthletePreferences}>
        Save
      </Button>
    </Stack>
  );
};
export default NbaAthletePreferenceSearch;

import useRailsToast from "@/components/utils/use-rails-toast";
import { Tooltip, Button, rem } from "@mantine/core";
import { IconCamera } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
const SaveAsHighlight = ({ id }) => {
  const railsToast = useRailsToast();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<Boolean>(false);
  const onClick = async () => {
    setLoading(true);
    const response = await fetch(`/api/v1/subtitles/${id}/save_as_highlight`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    setLoading(false);
    const status = response.status;
    const json = await response.json();
    if ([201].includes(status)) {
      railsToast({ info: "Highlight was successfully created!" }, status);
      navigate(`/highlights/${json.id}`);
    } else {
      railsToast(json, status);
    }
  };
  return (
    <Tooltip label="Convert to Highlight">
      <Button loading={loading} onClick={onClick} size="compact-sm">
        <IconCamera
          style={{
            width: rem(20),
            height: rem(20),
          }}
        />
      </Button>
    </Tooltip>
  );
};

export default SaveAsHighlight;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./style.module.css";
import { Highlight } from "../types";
import {
  Text,
  Center,
  Loader,
  Stack,
  Textarea,
  rem,
  Title,
  Button,
  Checkbox,
} from "@mantine/core";
import { SignedIn } from "@clerk/clerk-react";
import SearchableMultiSelect from "./searchable-multiselect";
import LikeButton from "./like-button";
import DislikeButton from "./dislike-button";
import Comments from "./comments";

const HighlightShow = () => {
  const { highlightId } = useParams();
  const [highlight, setHighlight] = useState<Highlight>(null);

  const fetchHighlight = async () => {
    const response = await fetch(`/api/v1/highlights/${highlightId}`);
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setHighlight({
        ...json,
      });
    }
  };

  useEffect(() => {
    fetchHighlight();
  }, []);

  if (!highlight) {
    return <Loader />;
  }

  if (!highlight.videoUrl) {
    return (
      <Stack align="center">
        <Title>Processing</Title>
        <Loader />
        <Text>Check back later for the processed video.</Text>
      </Stack>
    );
  }

  return (
    <Stack align="stretch">
      <Center>
        <video
          controls
          className={styles.videoContainer}
          src={highlight.videoUrl}
        />
      </Center>
      <Textarea
        label="Body"
        disabled
        name="highlight[body]"
        value={highlight.body}
      />
      <SignedIn>
        <SearchableMultiSelect
          highlightId={highlight.id}
          nbaAthleteAppearances={highlight.nbaAthleteAppearances}
          onRefresh={fetchHighlight}
        />
        <LikeButton highlightId={highlight.id} />
        <DislikeButton highlightId={highlight.id} />
        <Comments highlightId={highlight.id} />
      </SignedIn>
    </Stack>
  );
};

export default HighlightShow;

import { withUser } from "@clerk/clerk-react";
import * as React from "react";
import { Loader, Title } from "@mantine/core";
const UsersCurrentShow = ({ user }: { user?: any }) => {
  const [currentUser, setCurrentUser] = React.useState(null);

  React.useEffect(() => {
    let isMounted = true;
    const effect = async () => {
      const response = await fetch("/api/v1/users/current");
      const json = await response.json();
      const status = response.status;

      if ([200, 304].includes(status)) {
        isMounted && setCurrentUser(json);
      }
    };
    effect();

    return () => {
      isMounted = false;
    };
  }, [user]);

  if (!currentUser) {
    return <Loader />;
  } else {
    return <Title>Hello, {currentUser.username}</Title>;
  }
};

export default UsersCurrentShow;

export interface Highlight {
  id?: string;
  video: string;
  body: string;
}

export const newHighlight: Highlight = {
  video: "",
  body: "",
};

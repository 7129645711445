import { DISLIKE, Reaction } from "@/components/reactions/types";
import useRailsToast from "@/components/utils/use-rails-toast";
import { Checkbox } from "@mantine/core";
import { useState, useEffect } from "react";
const DislikeButton = ({ highlightId }) => {
  const railsToast = useRailsToast();
  const [loading, setLoading] = useState(true);
  const [reactions, setReactions] = useState<Reaction>([]);
  const dislikeReaction = reactions.find(({ reactionType }) => {
    return reactionType == DISLIKE;
  });
  const fetchReactions = async () => {
    const response = await fetch(`/api/v1/highlights/${highlightId}/reactions`);
    const json = await response.json();
    const status = response.status;
    setLoading(false);
    if ([200, 304].includes(status)) {
      setReactions(json);
    }
  };

  const createDislike = async () => {
    setLoading(true);
    const response = await fetch(`/api/v1/reactions`, {
      method: "POST",
      body: JSON.stringify({ highlightId, reactionType: DISLIKE }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([201].includes(status)) {
      fetchReactions();
    } else {
      setLoading(false);
      railsToast(json, status);
    }
  };

  const destroyDislike = async () => {
    setLoading(true);
    const response = await fetch(`/api/v1/reactions/${dislikeReaction.id}`, {
      method: "DELETE",
    });
    const status = response.status;
    if ([204].includes(status)) {
      fetchReactions();
    } else {
      setLoading(false);
      railsToast(await response.json(), status);
    }
  };

  useEffect(() => {
    fetchReactions();
  }, []);

  return (
    <Checkbox
      styles={{ input: { cursor: "pointer" }, label: { cursor: "pointer" } }}
      disabled={loading}
      label="Dislike"
      checked={!!dislikeReaction}
      onChange={(event) => {
        if (!!dislikeReaction) {
          destroyDislike();
        } else {
          createDislike();
        }
      }}
    />
  );
};

export default DislikeButton;

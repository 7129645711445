/**
 * Perform the direct upload of a single file.
 *
 * @providesModule Upload
 * @flow
 */

import * as ActiveStorage from "activestorage";
import { buildUrl, compactObject } from "./helpers";

class Upload {
  static CONVENTIONAL_DIRECT_UPLOADS_PATH =
    "/rails/active_storage/direct_uploads";

  static defaultOptions = {
    origin: {},
    directUploadsPath: Upload.CONVENTIONAL_DIRECT_UPLOADS_PATH,
  };

  directUpload;
  options;

  constructor(file, options) {
    this.options = { ...Upload.defaultOptions, ...compactObject(options) };
    this.directUpload = new ActiveStorage.DirectUpload(
      file,
      this.directUploadsUrl,
      this
    );

    this.handleChangeFile({ state: "waiting", id: this.id, file });
  }

  get id() {
    return `${this.directUpload.id}`;
  }

  get directUploadsUrl() {
    const { origin, directUploadsPath } = this.options;

    return buildUrl({ ...origin, path: directUploadsPath });
  }

  handleChangeFile = (upload) => {
    this.options.onChangeFile({ [this.id]: upload });
  };

  handleProgress = ({ loaded, total }) => {
    const progress = (loaded / total) * 100;

    this.handleChangeFile({
      state: "uploading",
      file: this.directUpload.file,
      id: this.id,
      progress,
    });
  };

  handleSuccess = (signedId) => {
    this.handleChangeFile({
      state: "finished",
      id: this.id,
      file: this.directUpload.file,
    });
    return signedId;
  };

  handleError = (error) => {
    this.handleChangeFile({
      state: "error",
      id: this.id,
      file: this.directUpload.file,
      error,
    });
    throw error;
  };

  start() {
    const promise = new Promise((resolve, reject) => {
      this.directUpload.create((error, attributes) => {
        if (error) reject(error);
        else resolve(attributes.signed_id);
      });
    });

    return promise.then(this.handleSuccess).catch(this.handleError);
  }

  /**
   * DirectUpload delegate protocol conformance
   */

  directUploadWillCreateBlobWithXHR(xhr) {
    this.addHeaders(xhr);

    this.options.onBeforeBlobRequest &&
      this.options.onBeforeBlobRequest({
        id: this.id,
        file: this.directUpload.file,
        xhr,
      });
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.options.onBeforeStorageRequest &&
      this.options.onBeforeStorageRequest({
        id: this.id,
        file: this.directUpload.file,
        xhr,
      });

    xhr.upload.addEventListener("progress", this.handleProgress);
  }

  /**
   * @private
   */

  addHeaders(xhr) {
    const headers = this.options.headers;
    if (headers) {
      for (const headerKey of Object.keys(headers)) {
        xhr.setRequestHeader(headerKey, headers[headerKey]);
      }
    }
  }
}

export default Upload;

import { useDisclosure } from "@mantine/hooks";
import { AppShell, Box, Burger, Group } from "@mantine/core";
import { UserButton } from "@clerk/clerk-react";
import React from "react";
import { NavbarSimple } from "./navbar";
const Shell = ({ children }: React.PropsWithChildren) => {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 200,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header p="md">
        <Group justify="space-between">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Box>Offtake</Box>
          <UserButton />
        </Group>
      </AppShell.Header>

      <AppShell.Navbar>
        <NavbarSimple toggle={toggle} />
      </AppShell.Navbar>

      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
};

export default Shell;

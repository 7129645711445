import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import styles from "./style.module.css";
import { AspectRatio, Center, Loader, Stack, Text, rem } from "@mantine/core";
import queryString from "query-string";
import MuxPlayer from "@mux/mux-player-react";

const RecordedStreamShow = () => {
  const { recordedStreamId } = useParams();
  const [recordedStream, setRecordedStream] = useState(null);
  const [searchParams] = useSearchParams();
  const parsedQueryParams = queryString.parse(searchParams.toString());

  const fetchRecordedStream = async () => {
    const response = await fetch(
      `/api/v1/recorded_streams/${recordedStreamId}`,
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setRecordedStream({
        ...json,
      });
    }
  };

  useEffect(() => {
    fetchRecordedStream();
  }, []);

  if (!recordedStream) {
    return <Loader />;
  }

  return (
    <Stack align="stretch">
      <Center>
        <MuxPlayer
          playbackId={recordedStream.muxPlaybackId}
          streamType="on-demand"
          startTime={parsedQueryParams.t || 0}
        />
      </Center>
    </Stack>
  );
};

export default RecordedStreamShow;

import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { Outlet, useNavigate } from "react-router-dom";
import Shell from "./shell";

const RootLayout = () => {
  const navigate = useNavigate();
  return (
    <ClerkProvider
      publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
      navigate={(to) => navigate(to)}
    >
      <SignedIn>
        <Shell>
          <Outlet />
        </Shell>
      </SignedIn>
      <SignedOut>
        <Outlet />
      </SignedOut>
    </ClerkProvider>
  );
};

export default RootLayout;

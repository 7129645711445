import {
  Button,
  Loader,
  Progress,
  Table,
  Text,
  Tooltip,
  rem,
} from "@mantine/core";
import { Subtitle } from "../types";
import { Link } from "react-router-dom";
import SaveAsHighlight from "./save-as-highlight";

const SearchBody = ({
  subtitles,
  loading,
  search,
}: {
  subtitles: Subtitle[];
  loading: Boolean;
  search: string;
}) => {
  if (search == "") {
    return <Text>Start typing to search through recorded streams</Text>;
  } else if (loading) {
    return <Progress m="md" radius="xl" size="xl" value={100} animated />;
  } else if (subtitles.length == 0) {
    return <Text>We were unable to find any similar clips to {search}.</Text>;
  } else {
    return (
      <Table
        data={{
          head: ["Body", "Actions"],
          body: subtitles.map(
            ({ id, startTimestamp, body, recordedStreamId }) => {
              console.log(startTimestamp);
              return [
                ,
                <Link
                  to={`/recorded_streams/${recordedStreamId}?t=${
                    startTimestamp - 5
                  }`}
                >
                  {body}
                </Link>,
                <SaveAsHighlight id={id} />,
              ];
            },
          ),
        }}
      />
    );
  }
};

export default SearchBody;

import React from "react";
import { ClerkProvider } from "@clerk/clerk-react";
import "@mantine/core/styles.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./root-layout";
import Home from "./home";
import routes from "./routes";
import CreateHighlight from "../highlights/create";
import HighlightShow from "../highlights/show";
import RecordedStreamSearch from "../recorded-streams/search";
import RecordedStreamShow from "../recorded-streams/show";
import SubtitleSearch from "../subtitles/search";
import AIHighlights from "../highlights/ai-highlights";
import CreateRecordedStream from "../recorded-streams/create";
import Settings from "./settings";
import {
  IconHighlight,
  IconDeviceComputerCamera,
  IconTextCaption,
  IconWand,
  IconSettings,
  IconUsersGroup,
  IconUser,
} from "@tabler/icons-react";
import NbaTeamPreferenceSearch from "../nba-team-preferences/search";
import NbaAthletePreferenceSearch from "../nba-athlete-preferences/search";

export const navbarLinks = [
  { link: "/", label: "Highlights", icon: IconHighlight },
  {
    link: "/recorded_streams",
    label: "Recorded Streams",
    icon: IconDeviceComputerCamera,
  },
  {
    link: "/subtitles",
    label: "Subtitles",
    icon: IconTextCaption,
  },
  {
    link: "/highlights/AI",
    label: "AI (Beta)",
    icon: IconWand,
  },
  {
    link: "/settings",
    label: "Settings",
    icon: IconSettings,
  },
  {
    link: "/nba_team_preferences",
    label: "Nba Team Preferences",
    icon: IconUsersGroup,
  },
  {
    link: "/nba_athlete_preferences",
    label: "Nba Athlete Preferences",
    icon: IconUser,
  },
];

const Providers = () => {
  const rootRouter = createBrowserRouter([
    {
      element: <RootLayout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/highlights/new", element: <CreateHighlight /> },
        {
          path: "/highlights/:highlightId",
          element: <HighlightShow />,
        },
        {
          path: "/recorded_streams",
          element: <RecordedStreamSearch />,
        },
        { path: "/recorded_streams/new", element: <CreateRecordedStream /> },
        {
          path: "/recorded_streams/:recordedStreamId",
          element: <RecordedStreamShow />,
        },
        {
          path: "/subtitles",
          element: <SubtitleSearch />,
        },
        {
          path: "/highlights/ai",
          element: <AIHighlights />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/nba_team_preferences",
          element: <NbaTeamPreferenceSearch />,
        },
        {
          path: "/nba_athlete_preferences",
          element: <NbaAthletePreferenceSearch />,
        },
      ],
    },
  ]);

  return <RouterProvider router={rootRouter} />;
};

export default Providers;

import * as React from "react";

import { useNavigate } from "react-router-dom";
// import useRailsToast from "@/components/utils/use-rails-toast";
import {
  Flex,
  Input,
  Button,
  Stack,
  Title,
  TextInput,
  Textarea,
  Select,
} from "@mantine/core";
import { RecordedStreamRequest } from "../types";
import useRailsToast from "@/components/utils/use-rails-toast";
import { SearchableMultiSelect } from "./searchable-multiselect";
import { NbaTeam } from "@/components/nba-teams/types";

const CreateRecordedStream = () => {
  const railsToast = useRailsToast();
  const navigate = useNavigate();
  const [value, setValue] = React.useState<NbaTeam[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [recordedStreamRequest, setRecordedStreamRequest] =
    React.useState<RecordedStreamRequest>({
      url: "",
      urlType: "youtube",
    });

  const onSubmit = async () => {
    setLoading(true);
    const response = await fetch(`/api/v1/recorded_streams`, {
      method: "POST",
      body: JSON.stringify({
        ...recordedStreamRequest,
        nbaTeamAppearancesAttributes: value.map(({ id }) => {
          return { nbaTeamId: id };
        }),
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    setLoading(false);
    const status = response.status;
    const json = await response.json();
    if ([201].includes(status)) {
      railsToast({ info: "Recorded Stream was successfully created!" }, status);
      navigate("/recorded_streams");
    } else {
      railsToast(json, status);
    }
  };

  return (
    <Stack align="stretch">
      <Title>New Highlight</Title>
      <TextInput
        label="Url"
        name="recorded_stream_request[url]"
        value={recordedStreamRequest.url}
        onChange={(e) =>
          setRecordedStreamRequest({
            ...recordedStreamRequest,
            url: e.currentTarget.value,
          })
        }
      />
      <Select label="Url Type" placeholder="Youtube" disabled />
      <SearchableMultiSelect value={value} setValue={setValue} />
      <Button
        disabled={!recordedStreamRequest.url}
        loading={loading}
        onClick={onSubmit}
      >
        Create
      </Button>
    </Stack>
  );
};

export default CreateRecordedStream;

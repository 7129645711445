import * as React from "react";

import { useNavigate } from "react-router-dom";
// import useRailsToast from "@/components/utils/use-rails-toast";
import {
  Flex,
  Input,
  Button,
  Stack,
  Title,
  TextInput,
  Textarea,
} from "@mantine/core";
import { Highlight, newHighlight } from "../types";
import useRailsToast from "@/components/utils/use-rails-toast";
import ActiveStorageUploader from "@/components/utils/active-storage-uploader";

const HighlightCreate = () => {
  const railsToast = useRailsToast();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [highlight, setHighlight] = React.useState<Highlight>(newHighlight);

  const onSubmit = async () => {
    setLoading(true);
    const response = await fetch(`/api/v1/highlights`, {
      method: "POST",
      body: JSON.stringify(highlight),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    setLoading(false);
    const status = response.status;
    const json = await response.json();
    if ([201].includes(status)) {
      railsToast({ info: "Highlight was successfully created!" }, status);
      navigate("/");
    } else {
      railsToast(json, status);
    }
  };

  return (
    <Stack align="stretch">
      <Title>New Highlight</Title>
      <ActiveStorageUploader
        onSuccess={(signedIds: string[]) => {
          setHighlight({
            ...highlight,
            video: signedIds[0],
          });
        }}
        onError={() => {
          setHighlight({
            ...highlight,
            video: null,
          });
        }}
      />
      <Textarea
        label="Body"
        name="highlight[body]"
        value={highlight.body}
        onChange={(e) =>
          setHighlight({ ...highlight, body: e.currentTarget.value })
        }
      />
      <Button disabled={!highlight.body} loading={loading} onClick={onSubmit}>
        Create
      </Button>
    </Stack>
  );
};

export default HighlightCreate;

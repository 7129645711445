import { useState, useEffect } from "react";
import {
  CheckIcon,
  Combobox,
  Group,
  Pill,
  PillsInput,
  useCombobox,
} from "@mantine/core";
import { NbaAthlete } from "@/components/nba-athletes/types";
import useRailsToast from "@/components/utils/use-rails-toast";

export function SearchableMultiSelect({
  highlightId,
  nbaAthleteAppearances,
  onRefresh,
}) {
  const [nbaAthletes, setNbaAthletes] = useState<NbaAthlete[]>([]);
  const railsToast = useRailsToast();
  const fetchNbaAthletes = async () => {
    const response = await fetch(`/api/v1/nba_athletes`);
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setNbaAthletes(json);
    }
  };

  useEffect(() => {
    fetchNbaAthletes();
  }, []);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const [search, setSearch] = useState("");

  const handleValueSelect = async (nbaAthleteId) => {
    const response = await fetch(`/api/v1/nba_athlete_appearances`, {
      method: "POST",
      body: JSON.stringify({ highlightId, nbaAthleteId }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([201].includes(status)) {
      onRefresh();
    } else {
      railsToast(json, status);
    }
  };

  const handleValueRemove = async (nbaAthleteAppearance: any) => {
    const response = await fetch(
      `/api/v1/nba_athlete_appearances/${nbaAthleteAppearance.id}`,
      {
        method: "DELETE",
      },
    );
    const status = response.status;

    if ([204].includes(status)) {
      onRefresh();
    } else {
      railsToast(await response.json(), status);
    }
  };

  const values = nbaAthleteAppearances.map((item) => (
    <Pill
      key={item.id}
      withRemoveButton
      onRemove={() => handleValueRemove(item)}
    >
      {item.nbaAthleteName}
    </Pill>
  ));

  const options = nbaAthletes
    .filter(({ name }) =>
      name.toLowerCase().includes(search.trim().toLowerCase()),
    )
    .filter(({ id }) => {
      return !nbaAthleteAppearances
        .map(({ nbaAthleteId }) => nbaAthleteId)
        .includes(id);
    })
    .map((item) => (
      <Combobox.Option
        value={item.id}
        key={item.id}
        active={nbaAthleteAppearances.map(({ id }) => id).includes(item.id)}
      >
        <Group gap="sm">
          {nbaAthleteAppearances.map(({ id }) => id).includes(item.id) ? (
            <CheckIcon size={12} />
          ) : null}
          <span>{item.name}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          label="Nba Athlete Apperances"
          onClick={() => combobox.openDropdown()}
        >
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="Search values"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(
                      nbaAthleteAppearances[nbaAthleteAppearances.length - 1],
                    );
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found...</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default SearchableMultiSelect;

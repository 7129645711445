import { useState, useEffect } from "react";
import {
  CheckIcon,
  Combobox,
  Group,
  Pill,
  PillsInput,
  useCombobox,
} from "@mantine/core";
import { NbaAthlete } from "@/components/nba-athletes/types";

export function SearchableMultiSelect({
  value,
  setValue,
}: {
  value: NbaAthlete[];
  setValue: any;
}) {
  const [nbaAthletes, setNbaAthletes] = useState<NbaAthlete[]>([]);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const [search, setSearch] = useState("");

  const fetchNbaAthletes = async () => {
    const response = await fetch(`/api/v1/nba_athletes`);
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setNbaAthletes(json);
    }
  };

  const fetchNbaAthletePreferences = async () => {
    const response = await fetch(`/api/v1/users/current`);
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      json.nbaAthletePreferences.map((nbaAthletePreference) => {
        const nbaAthlete = nbaAthletes.find(
          (nbaAthlete) => nbaAthlete.id == nbaAthletePreference,
        );
        !value.includes(nbaAthlete) && handleValueSelect(nbaAthlete);
      });
    }
  };

  useEffect(() => {
    fetchNbaAthletes();
  }, []);

  useEffect(() => {
    nbaAthletes &&
      nbaAthletes.length > 0 &&
      value.length == 0 &&
      fetchNbaAthletePreferences();
  }, [nbaAthletes]);

  const handleValueSelect = (val: NbaAthlete) =>
    setValue((current) =>
      current.includes(val)
        ? current.filter((v) => v !== val)
        : [...current, val],
    );

  const handleValueRemove = (val: NbaAthlete) =>
    setValue((current) => current.filter((v) => v !== val));

  const values = value.map((item) => {
    return (
      <Pill
        key={item.id}
        withRemoveButton
        onRemove={() => handleValueRemove(item)}
      >
        {item.name}
      </Pill>
    );
  });

  const options = nbaAthletes
    .filter((item) =>
      item.name.toLowerCase().includes(search.trim().toLowerCase()),
    )
    .map((item) => (
      <Combobox.Option value={item} key={item.id} active={value.includes(item)}>
        <Group gap="sm">
          {value.includes(item) ? <CheckIcon size={12} /> : null}
          <span>{item.name}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      // Wonder why this typecheck fails
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
    >
      <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()}>
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="Search values"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found...</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
